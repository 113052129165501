/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import config from 'config';
import queryString from 'query-string';

import * as Sentry from '@sentry/react';

export const fetchRequest = async (
  token: string | null | undefined,
  input: RequestInfo,
  init?: RequestInit
) => {
  let response;
  let error;
  try {
    const defaultHeaders = {
      Authorization: `Bearer ${token}`,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    };
    const customInit: RequestInit = {
      method: init?.method ? init.method : 'GET',
      headers: { ...defaultHeaders, ...(init?.headers || {}) },
      ...init,
    };
    const res = await fetch(input, customInit);
    if (res.status !== 204) {
      response = await res.json();
    } else {
      response = { statusCode: 204 };
    }
    if (!res.ok) {
      error = response && response.error ? response.error : 'request failed';
    }
  } catch (err) {
    console.error('Send request error', err);
    Sentry.captureException(err);
    error = err;
  }
  return { response, error };
};

export const verifyRecaptchaToken = (token, action: string) =>
  fetchRequest(token, `${config.serverUrl}/v1/recaptcha/verify`, {
    method: 'POST',
    body: JSON.stringify({ token, action }),
  });

export const register = (email: string, password: string) =>
  fetchRequest('', `${config.serverUrl}/v1/users/register`, {
    method: 'POST',
    body: JSON.stringify({ email, password }),
  });

export const basicLogin = (email: string, password: string) =>
  fetchRequest('', `${config.serverUrl}/v1/users/login`, {
    method: 'POST',
    body: JSON.stringify({ email, password }),
  });

export const otpLogin = (otp: string, mfaToken: string) =>
  fetchRequest('', `${config.serverUrl}/v1/users/login`, {
    method: 'POST',
    body: JSON.stringify({ otp, mfaToken }),
  });

export const startMfaFlow = (mfaToken: string, email: string) =>
  fetchRequest('', `${config.serverUrl}/v1/users/authenticators?email=${email}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${mfaToken}`,
    },
  });

export const getProfile = (token) =>
  fetchRequest(token, `${config.serverUrl}/v1/users/profile`, {
    method: 'GET',
  });

export const saveProfile = (token, payload: any) =>
  fetchRequest(token, `${config.serverUrl}/v1/users/profile`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });

export const signMessage = (token, network: 'ETH' | 'OTTO', isBinding: boolean) =>
  fetchRequest(token, `${config.serverUrl}/v1/users/sign-message`, {
    method: 'POST',
    body: JSON.stringify({ network, isBinding }),
  });

export const addEthWallet = (token, payload: any) =>
  fetchRequest(token, `${config.serverUrl}/v1/users/eth-wallet`, {
    method: 'PUT',
    body: JSON.stringify(payload),
  });

export const removeEthWallet = (token, payload: any) =>
  fetchRequest(token, `${config.serverUrl}/v1/users/eth-wallet`, {
    method: 'DELETE',
    body: JSON.stringify(payload),
  });

export const initIDVerificationRequest = (token, payload: any) =>
  fetchRequest(token, `${config.serverUrl}/v1/verification/id/init`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });

export const initEkycIDVerificationRequest = (token, payload: any) =>
  fetchRequest(token, `${config.serverUrl}/v1/ekyc/initialize`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });

export const initDocVerificationRequest = (token) =>
  fetchRequest(token, `${config.serverUrl}/v1/verification/doc/init`, {
    method: 'POST',
  });

// this will send the uploaded document to Jumio for doc verification
export const startDocVerificationRequest = (token) =>
  fetchRequest(token, `${config.serverUrl}/v1/verification/doc/start`, {
    method: 'POST',
  });

export const updateIdVerificationStatus = (token, payload: any) =>
  fetchRequest(token, `${config.serverUrl}/v1/verification/id/status`, {
    method: 'PUT',
    body: JSON.stringify(payload),
  });

export const updateDocVerificationStatus = (token, payload: any) =>
  fetchRequest(token, `${config.serverUrl}/v1/verification/doc/status`, {
    method: 'PUT',
    body: JSON.stringify(payload),
  });

export const updateEkycDocVerificationStatus = (payload: any) =>
  fetchRequest('', `${config.serverUrl}/v1/ekyc/status`, {
    method: 'PUT',
    body: JSON.stringify(payload),
  });

export const bindWalletAddress = (token, payload: any) =>
  fetchRequest(token, `${config.serverUrl}/v1/users/bind`, {
    method: 'PUT',
    body: JSON.stringify(payload),
  });

export const unbindWalletAddress = (token, payload: any) =>
  fetchRequest(token, `${config.serverUrl}/v1/users/unbind`, {
    method: 'PUT',
    body: JSON.stringify(payload),
  });

export const createNFT = (token) =>
  fetchRequest(token, `${config.serverUrl}/v1/nft/create`, {
    method: 'POST',
  });

export const changePassword = async (token) =>
  fetchRequest(token, `${config.serverUrl}/v1/users/change_password`, {
    method: 'POST',
  });

export const userForgotPassword = async (email: string) => {
  const url = `${config.serverUrl}/v1/users/forgot_password`;
  const res = await fetch(url, {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({ email }),
  });

  return res.ok;
};

export const resetMFA = async (token, auth0Id: string) =>
  fetchRequest(token, `${config.serverUrl}/v1/users/reset_mfa`, {
    method: 'POST',
    body: JSON.stringify({ auth0Id }),
  });

export const verifyEmail = async (email: string) => {
  const url = `${config.serverUrl}/v1/users/verify-email?email=${email}`;
  const res = await fetch(url, {
    method: 'POST',
    headers: { 'content-type': 'application/json' },
  });

  return res.ok ? { response: res.json() } : { error: 'Resend email failed' };
};

export const getUserOrders = async (token) =>
  fetchRequest(token, `${config.serverUrl}/v1/users/user/orders`, {
    method: 'GET',
  });

export const updateOttoSignature = (token, payload: { signature: string }) =>
  fetchRequest(token, `${config.serverUrl}/v1/users/signature`, {
    method: 'PUT',
    body: JSON.stringify(payload),
  });

export const getUserClaimRewards = (
  token,
  source: 'pre-mining' | 'plug-deposit' = 'pre-mining',
  page = 0,
  pageSize = 10
) =>
  fetchRequest(
    token,
    `${config.serverUrl}/v1/claim/rewards?skip=${
      page * pageSize
    }&limit=${pageSize}&source=${source}`,
    {
      method: 'GET',
    }
  );

export const getUserClaimRewardAmount = (token) =>
  fetchRequest(token, `${config.serverUrl}/v1/claim/rewards_total?status=pending`, {
    method: 'GET',
  });

export const getFeatureFlags = () =>
  fetchRequest('', `${config.serverUrl}/v1/feature-flags`, {
    method: 'GET',
  });

export const updateServerSign = (token) =>
  fetchRequest(token, `${config.serverUrl}/v1/users/sign`, {
    method: 'PUT',
  });

export const getUserAddressBookList = (token, skip?: number, limit?: number, search?: string) =>
  fetchRequest(
    token,
    `${config.serverUrl}/v1/address-books?skip=${skip || 0}&limit=${limit || 10}&search=${
      search || ''
    }`,
    {
      method: 'GET',
    }
  );

export const addUserAddressBook = (token, payload: { name?: string; walletAddress: string }) =>
  fetchRequest(token, `${config.serverUrl}/v1/address-books`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });

export const updateAddressBook = (
  token,
  payload: { id: string; name?: string; walletAddress: string }
) => {
  const { id, name, walletAddress } = payload;
  return fetchRequest(token, `${config.serverUrl}/v1/address-books/${id}`, {
    method: 'PUT',
    body: JSON.stringify({ name, walletAddress }),
  });
};

export const deleteAddressBook = (token, id: string) => {
  return fetchRequest(token, `${config.serverUrl}/v1/address-books/${id}`, {
    method: 'DELETE',
  });
};

export const getProposals = (params: {
  token: string | null | undefined;
  skip?: number;
  limit?: number;
  search?: string;
  status?: string;
  type?: string;
  isMine?: boolean;
}) => {
  const { token, skip, limit, search, status, type, isMine } = params;
  const url = queryString.stringifyUrl({
    url: `${config.serverUrl}/v1/governance/proposals`,
    query: { skip, limit, search, status, type, isMine },
  });
  return fetchRequest(token, url, {
    method: 'GET',
  });
};

export const getProposal = (token, id: string) =>
  fetchRequest(token, `${config.serverUrl}/v1/governance/proposals/${id}`, {
    method: 'GET',
  });

export const getProposalVotingStatistic = (token, proposalId: number) =>
  fetchRequest(
    token,
    `${config.serverUrl}/v1/governance/proposals/${proposalId}/voting-statistic`,
    {
      method: 'GET',
    }
  );

export const getProposalVotes = (token, proposalId: number) =>
  fetchRequest(token, `${config.serverUrl}/v1/governance/proposals/${proposalId}/votes`, {
    method: 'GET',
  });

export const createProposal = (token, payload: any) =>
  fetchRequest(token, `${config.serverUrl}/v1/governance/proposals`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });

export const editProposal = (token, proposalId: string | number, payload: any) =>
  fetchRequest(token, `${config.serverUrl}/v1/governance/proposals/${proposalId}`, {
    method: 'PUT',
    body: JSON.stringify(payload),
  });

export const deleteProposalAttachment = (token, proposalId: string | number) =>
  fetchRequest(token, `${config.serverUrl}/v1/governance/proposals/${proposalId}/attachment`, {
    method: 'DELETE',
  });

export const deleteProposal = (token, proposalId: string | number) =>
  fetchRequest(token, `${config.serverUrl}/v1/governance/proposals/${proposalId}`, {
    method: 'DELETE',
  });

export const uploadAttachment = (token, payload: { file: File }) => {
  const formData = new FormData();
  formData.append('file', payload.file);
  return fetchRequest(token, `${config.serverUrl}/v1/governance/upload`, {
    method: 'POST',
    body: formData,
  });
};

export const getUserBalance = (token, walletAddress: string, blockNumber: number) =>
  fetchRequest(
    token,
    `${config.serverUrl}/v1/governance/user-balance?walletAddress=${walletAddress}&blockNumber=${blockNumber}`,
    {
      method: 'GET',
    }
  );

export const getContributedFees = (
  token,
  proposalId: number,
  skip: number,
  limit: number,
  search?: string
) => {
  const url = queryString.stringifyUrl({
    url: `${config.serverUrl}/v1/governance/proposals/${proposalId}/contributed-fees`,
    query: { skip, limit, search },
  });
  return fetchRequest(token, url, {
    method: 'GET',
  });
};

export const getIntercomHmac = (token) =>
  fetchRequest(token, `${config.serverUrl}/v1/users/intercom/hmac`, {
    method: 'GET',
  });

export const addXRPLWallet = (token, payload: any) =>
  fetchRequest(token, `${config.serverUrl}/v1/users/xrpl-wallet`, {
    method: 'PUT',
    body: JSON.stringify(payload),
  });

export const removeXRPLWallet = (token, payload: any) =>
  fetchRequest(token, `${config.serverUrl}/v1/users/xrpl-wallet`, {
    method: 'DELETE',
    body: JSON.stringify(payload),
  });

export const submitVote = (
  token,
  payload: { walletAddress: string; proposalId: number; decision: boolean }
) =>
  fetchRequest(token, `${config.serverUrl}/v1/governance/vote`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });

export const getPendingVote = (token, walletAddress: string, proposalId: number) =>
  fetchRequest(
    token,
    `${config.serverUrl}/v1/governance/vote/pending?walletAddress=${walletAddress}&proposalId=${proposalId}`,
    {
      method: 'GET',
    }
  );

export const getCustodyAssets = (token) =>
  fetchRequest(token, `${config.serverUrl}/v1/assets-onboarding/supported-assets`, {
    method: 'GET',
  });

export const getNetworkByAssetSymbol = (token, symbol: string) =>
  fetchRequest(token, `${config.serverUrl}/v1/assets-onboarding/networks/${symbol}`, {
    method: 'GET',
  });

export const getDepositAddressByAssetAndNetwork = (token, symbol: string, network: string) =>
  fetchRequest(
    token,
    `${config.serverUrl}/v1/assets-onboarding/deposit-address?token=${symbol}&network=${network}`,
    {
      method: 'GET',
    }
  );

export const generateDepositAddress = (token, payload: { token: string; network: string }) =>
  fetchRequest(token, `${config.serverUrl}/v1/assets-onboarding/deposit-address`, {
    method: 'POST',
    body: JSON.stringify(payload),
  });
