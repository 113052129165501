import { useTranslation } from 'react-i18next';

import { Layout, Space } from 'antd';

import clxs from 'classnames';

import Divider from 'components/divider';
import { Text } from 'components/typography';
import DiscordSvg from 'resources/svg/discord.svg';
import MediumSvg from 'resources/svg/medium.svg';
import TwitterSvg from 'resources/svg/twitter.svg';
import YoutubeSvg from 'resources/svg/youtube.svg';

import s from './s.module.scss';

const { Footer } = Layout;

const DashboardFooter = () => {
  const { t } = useTranslation();
  return (
    <Footer className={s.footer}>
      <Text type="p2" color="white" weight="semibold">
        Join us on social media
      </Text>

      <Space className={s.icons} direction="horizontal" size={'large'}>
        <a href="https://twitter.com/OttoBlockchain" target="_blank" rel="noopener noreferrer">
          <img src={TwitterSvg} alt="twitter" />
        </a>
        <a href="https://discord.gg/zng3qMqWT9" target="_blank" rel="noopener noreferrer">
          <img src={DiscordSvg} alt="discord" />
        </a>
        <a
          href="https://www.youtube.com/@ottoblockchain2986"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src={YoutubeSvg} alt="facebook" />
        </a>
        <a href="https://ottoblockchain.medium.com/" target="_blank" rel="noopener noreferrer">
          <img src={MediumSvg} alt="medium" />
        </a>
      </Space>

      <Space className={s.links} direction="horizontal" size={'large'}>
        <a
          className={clxs(s.text, s.link)}
          href="https://youtu.be/o4vayP5LxO8"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('footer_link_how_it_works')}
        </a>
        <a
          className={clxs(s.text, s.link)}
          href="https://www.ottoblockchain.com/token"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('footer_link_token_info')}
        </a>
        <a
          className={clxs(s.text, s.link)}
          href="https://www.ottoblockchain.com/our-vision"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('footer_link_about_us')}
        </a>
        <a
          className={clxs(s.text, s.link)}
          href="https://twitter.com/OttoBlockchain"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('footer_link_social_media')}
        </a>
        <a
          className={clxs(s.text, s.link)}
          href="./terms.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('footer_link_terms_and_conditions')}
        </a>
        <a
          className={clxs(s.text, s.link)}
          href="./risk.html"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('footer_link_risk_disclosure_statement')}
        </a>
        <a
          className={clxs(s.text, s.link)}
          href="https://www.futureverse.com/legal/privacy"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t('footer_link_privacy_policy')}
        </a>
      </Space>
      <Divider className="mt-16" />
      <div className={s.declaration}>
        <div className={s.declaration_text}>
          All content on this website is the property of CNZFV Ltd or its licensors or associated
          companies and is protected by copyright, trade marks (whether registered or not) and other
          applicable laws. This website is licensed and operated by CNZFV Ltd on behalf of The Otto
          Network community. The contents and opinions of this website do not necessarily reflect
          the opinions of CNZFV Ltd.
        </div>
        <div className={s.declaration_text}>
          This website may include links to projects, dApps and third-party services etc., and CNZFV
          does not warrant that the information available from these is true, correct, complete or
          up-to-date (or similar) or accept responsibility for anything in connection to them. By
          using or accessing this website (or similar) you expressly waive any claim for any loss,
          damage or cost that you may incur from using or accessing this website or by using or
          accessing links to projects, dApps and third-party services etc available on this website.
        </div>
        <div className={s.copyright}>
          <Text type="p2" color="white" uppercase className={s.copyright_text}>
            Legals
          </Text>
          <Text type="p2" color="white" className={s.copyright_text}>
            © 2023 &rarr; &infin;
          </Text>
        </div>
      </div>
    </Footer>
  );
};

export default DashboardFooter;
