import { initReactI18next } from 'react-i18next';

import i18n from 'i18next';
import Backend from 'i18next-http-backend';

i18n
  .use(Backend)
  .use(initReactI18next) // bind react-i18next to the instance
  .init(
    {
      debug: false,
      supportedLngs: ['en', 'ja', 'zh-CN', 'ko', 'zh-TW'],
      fallbackLng: 'en',
      ns: [
        'translations',
        'dashboard',
        'staking',
        'plug_utility',
        'airdrop',
        'assets_onboarding',
        'address_book',
        'dex',
        'governance',
        'swap',
      ],
      defaultNS: 'translations',
      react: {
        useSuspense: false,
      },
    },
    (err, t) => {
      t('dashboard_staking_heading', { ns: 'dashboard' });
    }
  );
